<template>
  <v-dialog
    persistent
    class="b-dialog"
    transition="dialog-bottom-transition"
    :retain-focus="false"
    :value="dialog"
    :max-width="800"
    @input="closeModal(false)"
  >
    <v-form @submit.prevent="save" ref="form" lazy-validation v-model="valid">
      <v-card>
        <v-toolbar color="primary" dark>
          <v-toolbar-title>{{ title }}</v-toolbar-title>
          <v-spacer />
          <v-btn
            icon
            id="btn-close"
            name="btn-close"
            @click="closeModal(false)"
          >
            <v-icon>fa-solid fa-xmark</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="b-dialog--content">
          <v-row dense>
            <v-col cols="12" xl="5" lg="5" md="5" sm="6">
              <BaseCustomers
                id="customerId"
                name="customerId"
                label="Subestipulante"
                hide-details
                required
                :customerId="customerId"
                :sub="true"
                :rules="[required]"
                v-model="model.customerId"
              />
            </v-col>
            <v-col cols="12" xl="4" lg="4" md="4" sm="6">
              <BaseNumber
                id="amountLives"
                name="amountLives"
                label="Quantidade de vidas"
                maxlength="6"
                required
                :rules="[required]"
                v-model="model.amountLives"
              />
            </v-col>
            <v-col cols="12" xl="3" lg="3" md="3" sm="6">
              <BaseDatePicker
                id="effectiveDate"
                name="effectiveDate"
                label="Início da vigência"
                required
                :rules="[required]"
                v-model="model.effectiveDate"
              />
            </v-col>
          </v-row>
          <v-divider class="mb-3" />
          <v-row dense>
            <v-col cols="12" xl="6" lg="6" md="6" sm="6">
              <v-radio-group
                id="hasLeaveBeneficiaries"
                name="hasLeaveBeneficiaries"
                label="Possui beneficiários afastados?"
                class="ma-0"
                v-model="model.hasLeaveBeneficiaries"
              >
                <v-radio label="Sim" color="primary" :value="true"></v-radio>
                <v-radio label="Não" color="primary" :value="false"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col
              cols="12"
              xl="6"
              lg="6"
              md="6"
              sm="6"
              v-if="model.hasLeaveBeneficiaries"
            >
              <BaseNumber
                id="amountLeaveBeneficiaries"
                name="amountLeaveBeneficiaries"
                label="Quantidade de beneficiários"
                maxlength="4"
                required
                :rules="[required]"
                v-model="model.amountLeaveBeneficiaries"
              />
            </v-col>
          </v-row>
          <v-divider class="mb-3" />
          <v-row dense>
            <v-col cols="12" xl="6" lg="6" md="6" sm="6">
              <v-radio-group
                id="hasAdditiveCode"
                name="hasAdditiveCode"
                label="Possui código de aditivo?"
                class="ma-0"
                v-model="model.hasAdditiveCode"
              >
                <v-radio label="Sim" color="primary" :value="true"></v-radio>
                <v-radio label="Não" color="primary" :value="false"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col
              cols="12"
              xl="6"
              lg="6"
              md="6"
              sm="6"
              v-if="model.hasAdditiveCode"
            >
              <v-text-field
                id="additiveCode"
                name="additiveCode"
                label="Código aditivo"
                maxlength="15"
                dense
                outlined
                required
                :rules="[required]"
                v-model="model.additiveCode"
              />
            </v-col>
          </v-row>
          <v-divider class="mb-3" v-if="model.id" />
          <v-row dense v-if="model.id">
            <v-col cols="12" xl="6" lg="6" md="6" sm="6">
              <v-radio-group
                id="active"
                name="active"
                label="Ativo"
                class="ma-0"
                v-model="model.active"
              >
                <v-radio label="Sim" color="primary" :value="true"></v-radio>
                <v-radio label="Não" color="primary" :value="false"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
        </v-card-text>
        <template v-if="contract.situation !== 'Cancelado'">
          <v-card-actions
            class="b-dialog--actions"
            v-permission="{
              roles: ['A1', 'AC1', 'AC2', 'AC3', 'ACI1', 'ACI2', 'ACI3'],
              operator: '==='
            }"
          >
            <BaseButton
              id="btn-save"
              name="btn-save"
              type="submit"
              color="primary"
              title="Salvar"
              :disabled="!valid"
            />
          </v-card-actions>
        </template>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { rulesMixin } from '@/mixins/rules';
import { mapGetters } from 'vuex';
import { showMessage } from '@/helpers/messages';
import { ContractService } from '@/services/api/contracts';

export default {
  mixins: [rulesMixin],

  props: {
    dialog: {
      type: Boolean,
      default: false,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    edit: {
      type: Boolean,
      required: true,
      default: false
    },
    selectItem: {
      type: Object,
      required: true
    },
    productType: {
      type: String
    },
    contractId: {
      type: String,
      required: true
    },
    customerId: {
      type: String
    }
  },

  data: () => ({
    valid: true,
    model: {
      id: null,
      customerId: null,
      customerName: null,
      amountLives: null,
      hasLeaveBeneficiaries: false,
      amountLeaveBeneficiaries: null,
      hasAdditiveCode: false,
      additiveCode: null,
      effectiveDate: null,
      active: true
    }
  }),

  computed: {
    ...mapGetters({
      contract: 'contracts/getContractById'
    })
  },

  created() {
    if (this.edit) {
      this.model = Object.assign({}, this.selectItem);
    }
  },

  methods: {
    closeModal(value) {
      this.$emit('closeModal', value);
    },

    save() {
      if (!this.$refs.form.validate(true)) {
        return;
      }

      const params = {
        contractId: this.contractId,
        productType: this.productType,
        ...this.model
      };

      if (this.edit) {
        this.update(params);
      } else {
        this.create(params);
      }
    },

    async create(params) {
      try {
        const contractService = new ContractService();
        const { status } = await contractService.createCustomers(params);

        if (status === 200 || status === 204) {
          showMessage('success', 'Operação realizada com sucesso');
          this.closeModal(true);
        }
      } catch (error) {
        throw new Error(error);
      }
    },

    async update(params) {
      try {
        const contractService = new ContractService();
        const { status } = await contractService.updateCustomers(params);

        if (status === 204) {
          showMessage('success', 'Operação realizada com sucesso');
          this.closeModal(true);
        }
      } catch (error) {
        throw new Error(error);
      }
    }
  }
};
</script>
